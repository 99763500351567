import React from 'react'


function Dashboard() {
  return (
    <div>
        Welcome to dashboard
    </div>
  )
}

export default Dashboard