import React from 'react';
import Dash from '../../components/AdminComponents/Dashboard/Dash';
import AdminHeader from '../../components/AdminComponents/Header/AdminHeader';
import Footer from '../../components/AdminComponents/Footer/Footer';

function Dashboard() {
  return (
    <div>
        <Dash/>
    </div>
  );
}

export default Dashboard;
